import React from 'react';
import { LockOutlined, UserOutlined, GlobalOutlined, ApartmentOutlined } from '@ant-design/icons';
import '../styles/login.style.css';
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { auth } from '../system/AccountManager';

export default function Login() {
  const dispatch = useDispatch();
  
  const onFinish = (values) => {
    auth(values);
    dispatch({
      type: 'SET_DATA',
      payload: { status: true, ...values }
    });
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <p className="title">Авторизация</p>
        <Form
          name="login"
          initialValues={{
            remember: true,
          }}
          style={{
            width: '65%',
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="server"
            rules={[
              {
                required: true,
                message: 'Вы не указали сервер',
              },
            ]}
          >
            <Input prefix={<ApartmentOutlined />} placeholder="Сервер" />
          </Form.Item>

          <Form.Item
            name="port"
            rules={[
              {
                required: true,
                message: 'Вы не указали порт сервера',
              },
            ]}
          >
            <Input prefix={<GlobalOutlined />} placeholder="Порт" />
          </Form.Item>

          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Вы не указали имя пользователя',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Имя пользователя" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Вы не указали пароль',
              },
            ]}
          >
            <Input prefix={<LockOutlined />} type="password" placeholder="Пароль" />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}