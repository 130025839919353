import React, { useState } from 'react';
import { FolderOutlined, MailOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Form, Input, Layout, Menu, Modal, theme, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { logout } from '../../system/AccountManager';
import { useDispatch } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
const { Header, Sider } = Layout;

function groupbyFrom(messages) {
  const result = {};

  for (const message of messages) {
    const { from } = message;
    if (!result[from]) {
      result[from] = {
        from,
        messages: []
      };
    };
    result[from].messages.push(message);
  };

  return Object.values(result);
};

const messages = [
  {
    id: '35423',
    from: 'fox@zlr.su',
    name: 'Зеля',
    topic: 'фыр',
    text: 'врур фыр мяу'
  },
  {
    id: '4525637',
    from: 'foxik@gmail.com',
    name: 'Лисик',
    topic: 'мяф',
    text: 'Я лися'
  },
  {
    id: '347473',
    from: 'fox3@zlr.su',
    name: 'Зеля',
    topic: 'МЯУ',
    text: 'хы хы хы'
  },
  {
    id: '4356876',
    from: 'foxfir@zlr.su',
    name: 'Зеля',
    topic: 'привет',
    text: 'приветики фыр фыр'
  },
  {
    id: '23453454788',
    from: 'fox@gmail.com',
    name: 'Лисик',
    topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
    text: 'а ты лисик?'
  },
  {
    id: '3465835678',
    from: 'foxfiiir3@zlr.su',
    name: 'Зеля',
    topic: 'фыр',
    text: 'врур фыр мяу'
  },
  {
    id: '36458365786',
    from: 'foxik4d@gmail.com',
    name: 'Лисик',
    topic: 'мяф',
    text: 'Я лися'
  },
  {
    id: '456383645873456',
    from: 'fox340@zlr.su',
    name: 'Зеля',
    topic: 'МЯУ',
    text: 'хы хы хы'
  },
  {
    id: '345773468',
    from: 'foxfir12@zlr.su',
    name: 'Зеля',
    topic: 'привет',
    text: 'приветики фыр фыр'
  },
  {
    id: '345354',
    from: 'foxyamya@gmail.com',
    name: 'Лисик',
    topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
    text: 'а ты лисик?'
  },
  {
    id: '45684568',
    from: 'foxii@zlr.su',
    name: 'Зеля',
    topic: 'фыр',
    text: 'врур фыр мяу'
  },
  {
    id: '4568456',
    from: 'foxikmrr@gmail.com',
    name: 'Лисик',
    topic: 'мяф',
    text: 'Я лися'
  },
  {
    id: '45684568',
    from: 'fox39@zlr.su',
    name: 'Зеля',
    topic: 'МЯУ',
    text: 'хы хы хы'
  },
  {
    id: '45684568',
    from: 'foxfirrr@zlr.su',
    name: 'Зеля',
    topic: 'привет',
    text: 'приветики фыр фыр'
  },
  {
    id: '34567',
    from: 'foxrr@gmail.com',
    name: 'Лисик',
    topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
    text: 'а ты лисик?'
  },
  {
    id: '4579457',
    from: 'foxfiiir@zlr.su',
    name: 'Зеля',
    topic: 'фыр',
    text: 'врур фыр мяу'
  },
  {
    id: '4579',
    from: 'foxik3d@gmail.com',
    name: 'Лисик',
    topic: 'мяф',
    text: 'Я лися'
  },
  {
    id: '45684538',
    from: 'fox346@zlr.su',
    name: 'Зеля',
    topic: 'МЯУ',
    text: 'хы хы хы'
  },
  {
    id: '7457745647',
    from: 'foxfir31@zlr.su',
    name: 'Зеля',
    topic: 'привет',
    text: 'приветики фыр фыр'
  },
  {
    id: '46457',
    from: 'foxyaoo@gmail.com',
    name: 'Лисик',
    topic: 'фыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыыр',
    text: 'а ты лисик?'
  },
];

const items = groupbyFrom(messages).map(
  (messagesGroup, index) => ({
    key: String(index + 1),
    icon: React.createElement(FolderOutlined),
    label: messagesGroup.from,
    children: messagesGroup.messages.map((message, i) => {
      return {
        key: String(index * 4 + i + 1),
        icon: React.createElement(MailOutlined),
        label: (
          <Link to={`/m/${message.id}`} >{message.topic}</Link>
        ),
      }
    })
  }),
);
const MailLayout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
    <Modal
      title="Написать письмо"
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelText='Отмена'
      okText='Отправить'
    >
      <Form
          name="send-mail"
          initialValues={{
            remember: true,
          }}
          style={{
            width: '100%',
          }}
          layout='vertical'
        >
          <Form.Item
            name="mail-adress"
            rules={[
              {
                required: true,
                message: 'Вы не указали адресс',
              },
            ]}
          >
            <Input placeholder="Адресс" />
          </Form.Item>

          <Form.Item
            name="mail-topic"
          >
            <Input placeholder="Тема" />
          </Form.Item>

          <Form.Item
            name="mail-content"
          >
            <TextArea placeholder="Сообщение" />
          </Form.Item>
          <Form.Item label="Файлы" valuePropName="fileList">
            <Upload action="/upload.do" listType="picture-card">
              <button style={{ border: 0, background: 'none', color: 'white' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Загрузить</div>
              </button>
            </Upload>
          </Form.Item>
        </Form>
    </Modal>
    <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            height: '48px',
            lineHeight: '48px',
            paddingLeft: '42px',
            paddingRight: '20px',
            alignItems: 'center'
          }}
        >
          <Link to='/' className='Logo' >FoxMail</Link>
          <Dropdown menu={{
            items: [
                {
                  key: '1',
                  label: 'Написать письмо',
                  onClick: showModal
                },
                {
                  key: '2',
                  danger: true,
                  label: 'Выйти',
                  onClick: () => {
                    logout();
                    dispatch({
                      type: 'SET_DATA',
                      payload: { status: false }
                    });
                  }
                }
              ]
}} placement="bottomRight" trigger={['click']} arrow>
            <Avatar size={36} style={{ marginLeft: '97%', marginTop: '-111px', cursor: 'pointer' }} icon={<UserOutlined />} />
          </Dropdown>
        </Header>
      </Layout>
    <Layout style={{ height: '100%', backgroundColor: '#121212' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        style={{ backgroundColor: '#272727', height: 'calc(100vh - 48px)', overflowX: 'auto' }}
      >
        <Menu style={{ backgroundColor: '#272727' }} theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items} />
      </Sider>
      <div style={{ margin: '26px', width: '100%' }} >{children}</div>
    </Layout>
    </>
  );
};
export default MailLayout;