import { Flex, Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

export function getLocalData() {
    const authData = localStorage.getItem('account');
    if (authData) {
        return JSON.stringify(authData);
    } else {
        return null;
    }
};

export function setLocalData(data) {
    localStorage.setItem('account', JSON.stringify(data));
};

export function clearLocalData() {
    const authData = localStorage.getItem('account');
    if (authData) {
        localStorage.removeItem('account');
        return true;
    } else {
        return false;
    }
};

export function auth(data) {
    setLocalData(data);
};

export function logout() {

    clearLocalData();
};

export function AuthCheck({ auth, component }) {
    const authState = useSelector(state => state.auth);

    if (authState.status === null ) {
        return (
            <Flex align="center" justify='center' gap="middle" style={{ height: '100vh', width: '100%' }} >
                <Spin indicator={<LoadingOutlined style={{ fontSize: '48px', color: '#fff' }} spin />} />
            </Flex>
        );
    };
    
    if (auth) {
        if (authState.status) {
            return component;
        } else {
            return <Navigate to='/login' replace />;
        }
    } else {
        if (!authState.status) {
            return component;
        } else {
            return <Navigate to='/' replace />;
        }
    }
}